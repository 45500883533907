<template>
  <BCard body-class="gap-[32px] d-flex flex-column">
    <div class="text-black text-2xl font-semibold d-flex">
      <button
        class="button-primary text-white d-flex mr-1 px-[5px]"
        @click="$router.back()"
      >
        <span
          class="k-arrow-left-2 h-100 font-bold text-10"
          style="line-height: 1.5;"
        />
      </button>
      <span class="text-10 text--dark">Detail User</span>
    </div>
    <div class="flex items-center gap-[24px]">
      <img
        src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
        alt="Komerce"
        class="w-[40px] h-[40px] rounded-circle"
      >
      <div class="flex flex-column w-full gap-[12px]">
        <span class="text-muted">Nama</span>
        <span class="text-black font-semibold">{{ detailUser.full_name || '-' }}</span>
      </div>
      <div class="flex flex-column w-full gap-[12px]">
        <span class="text-muted">Email</span>
        <span class="text-black font-semibold">{{ detailUser.email || '-' }}</span>
      </div>
    </div>
    <div class="flex items-center gap-[24px] pb-2 border-b">
      <div class="flex flex-column w-[calc(100%+140px)] gap-[12px]">
        <span class="text-muted">Nomor Whatsapp</span>
        <span class="text-black font-semibold">+{{ detailUser.phone_number || '-' }} <button
          class="px-[8px] py-[2px] rounded bg-[#34A853] text-white"
          @click="followUp(detailUser.phone_number)"
        >Follow Up <span class="k-Whatsapp" /></button></span>
      </div>
      <div class="flex flex-column w-full gap-[12px]">
        <span class="text-muted">Sudah Follow Up?</span>
        <span class="text-black font-semibold">
          <b-form-checkbox
            v-model="detailUser.is_follow_up"
            :disabled="Boolean(detailUser.is_follow_up)"
            :value="1"
            @change="updateStatus()"
          >
            Sudah Follow Up
          </b-form-checkbox>
        </span>
      </div>
    </div>
    <section>
      <h5 class="mb-1">
        Kuisioner
      </h5>
      <div class="d-flex flex-column flex-md-row gap-3 align-items-center w-100 pb-3 pb-md-5 border-bottom">
        <div class="d-flex flex-column gap-12 w-100">
          <div class="d-flex flex-column align-items-start gap-5 text-left">
            <div class="text-[#828282] col-12 p-0 d-flex">
              <div class="col-1 p-0 text-[#34A853]">
                1.
              </div>
              Minat pada layanan?
            </div>
            <span class="col-12 p-0 d-flex text-[#333333]">
              <div class="col-1 p-0" />
              {{ detailUser.field_interest || '-' }}
            </span>
          </div>
          <div class="d-flex flex-column align-items-start gap-5 text-left">
            <div class="text-[#828282] col-12 p-0 d-flex">
              <div class="col-1 p-0 text-[#34A853]">
                2.
              </div>
              Tipe Bisnis?
            </div>
            <span class="col-12 p-0 d-flex text-[#333333]">
              <div class="col-1 p-0" />
              {{ detailUser.business_type_name || '-' }}
            </span>
          </div>
          <div class="d-flex flex-column align-items-start gap-5 text-left">
            <div class="text-[#828282] col-12 p-0 d-flex">
              <div class="col-1 p-0 text-[#34A853]">
                3.
              </div>
              Sektor Bisnis Yang dijalankan?
            </div>
            <span class="col-12 p-0 d-flex text-[#333333]">
              <div class="col-1 p-0" />
              {{ detailUser.business_sector_name || '-' }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column gap-12 w-100">
          <div class="d-flex flex-column align-items-start gap-5 text-left">
            <div class="text-[#828282] col-12 p-0 d-flex">
              <div class="col-1 p-0 text-[#34A853]">
                4.
              </div>
              Sudah berapa lama menjalakan bisnis di marketplace seperti shopee, tokopedia, bukalapak?
            </div>
            <span class="col-12 p-0 d-flex text-[#333333]">
              <div class="col-1 p-0" />
              {{ detailUser.marketplace_experience || '-' }}
            </span>
          </div>
          <div class="d-flex flex-column align-items-start gap-5 text-left">
            <div class="text-[#828282] col-12 p-0 d-flex">
              <div class="col-1 p-0 text-[#34A853]">
                5.
              </div>
              Sudah jalankan iklan seperti Facebook ads atau Google ads selama berapa lama ?
            </div>
            <span class="col-12 p-0 d-flex text-[#333333]">
              <div class="col-1 p-0" />
              {{ detailUser.ads_experience || '-' }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'

export default {
  data() {
    return {
      alertError,
      detailUser: {},
    }
  },
  mounted() {
    this.getDetailUser()
  },
  methods: {
    async getDetailUser() {
      const url = `v1/talent_pool/user/${this.$route.params.id}/detail`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailUser = await data
        })
        .catch(err => {
          this.alertError(err)
        })
    },
    followUp(phone) {
      window.open(`https://wa.me/${phone}`, '_blank')
    },
    updateStatus() {
      const url = `v1/talent_pool/user/${this.$route.params.id}/change_follow_up`
      komtimAxiosIns
        .put(url)
        .then(async res => {
          this.getDetailUser()
        })
        .catch(err => {
          this.getDetailUser()
          this.alertError(err)
        })
    },
  },
}
</script>
